import React, { createContext, useContext } from 'react'

// queries
import { useUser } from '@layout/queries'

// components
import Spinner from '@components/Spinner'
import FetchError from '@components/FetchError'

// Create the context with a default value of null
const UserContext = createContext(null)

// Hook to use it all over the app
export const useUserCtx = () => {
    return useContext(UserContext)
}

export const UserContextProvider = ({ children }) => {
    const { data, isLoading, isError } = useUser()

    if (isLoading) {
        return <Spinner mode='FULLSCREEN' />
    }

    if (isError) {
        return <FetchError />
    }

    return <UserContext.Provider value={data}>{children}</UserContext.Provider>
}
