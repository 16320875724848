import React from 'react'

// components
import Action from '@components/Action'
import IsOnline from '@components/IsOnline'
import IconYogaLotus from '@assets/svg/lotus.svg'
import IconHome from '@assets/svg/home-lg-alt.svg'

export default function FullPageNotice({ title, text, action, actionText, icon = IconHome }) {
    return (
        <main className='relative isolate grid min-h-[calc(100vh-108px)] w-screen place-items-center bg-white px-4'>
            <div className='fixed top-0 w-full'>
                <IsOnline />
            </div>
            <div className='absolute inset-0 -z-10 h-full w-full' />
            <div className='mx-auto max-w-xl px-6 pb-12 text-center'>
                <IconYogaLotus className='mx-auto size-16 rounded-full bg-studio/10 stroke-studio p-3' />
                <h1 className='mt-4 font-lmp-regular text-2xl'>{title}</h1>
                <p className='mt-2 text-center text-sm text-[#777777]'>{text}</p>
                {action && (
                    <div className='mx-auto mt-8 block text-center'>
                        <Action onClick={action} text={actionText} mode='studio' icon={icon} />
                    </div>
                )}
            </div>
        </main>
    )
}
