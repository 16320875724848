import React, { useState, useEffect } from 'react'
import IconWifiSlash from '@assets/svg/wifi-slash.svg'

export default function IsOnline() {
    const [isOnline, setIsOnline] = useState(navigator.onLine)

    const getStatus = () => {
        setIsOnline(navigator.onLine)
    }

    useEffect(() => {
        window.addEventListener('online', getStatus)
        window.addEventListener('offline', getStatus)

        return () => {
            window.removeEventListener('online', getStatus)
            window.removeEventListener('offline', getStatus)
        }
    }, [])

    return isOnline ? null : (
        <div className='flex w-full items-center justify-center bg-mandy p-2 text-sm text-white'>
            <IconWifiSlash className='mr-2 h-5 w-5 fill-current' /> ¡No tienes conexión a Internet!
        </div>
    )
}
