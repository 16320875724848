import React from 'react'

const Balls = ({ ballColor }) => {
    const balls = [
        { animationDelay: '-0.32s', delayClass: 'delay-75' },
        { animationDelay: '-0.16s', delayClass: 'delay-100' },
        { animationDelay: '0s', delayClass: 'delay-150' },
    ]

    return (
        <div className='mx-auto flex items-center justify-items-center gap-3 text-center'>
            {balls.map((ball, index) => (
                <div
                    key={index}
                    className={`${ballColor} size-3 animate-[tribu-spinner-ball_1.4s_infinite_ease-in-out_both] rounded-full ${ball.delayClass} ease-in-out`}
                    style={{ animationDelay: ball.animationDelay }}
                />
            ))}
        </div>
    )
}

export default function Spinner({ mode }) {
    if (mode === 'SECTION') {
        return (
            <div className='flex items-center justify-center px-4 py-12'>
                <Balls ballColor='bg-emperor' />
            </div>
        )
    }

    if (mode === 'FULLSCREEN') {
        return (
            <div className='fixed inset-0 z-[999999999] bg-white'>
                <div className='flex min-h-full justify-center p-4 sm:items-center sm:p-0'>
                    <Balls ballColor='bg-emperor' />
                </div>
            </div>
        )
    }

    if (mode === 'BUTTON') {
        return (
            <div className='mx-auto flex justify-center'>
                <Balls ballColor='bg-white' />
            </div>
        )
    }

    return null
}
