import React from 'react'

// css
import '@assets/css/main.css'

// configure layout
import Layout from '@layout'

// props provide same data to Layout as Page element will get
// including location, data, etc. - you don't need to pass it
export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
}

// smooth scroll behavior polyfill
export const onClientEntry = () => {
    window.addEventListener('load', () => {
        //
        // STEP 1: Register a Service Worker
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register('/sw.js')
                .then((registration) => {
                    console.log('Service Worker registered with scope:', registration.scope)
                })
                .catch((error) => {
                    console.log('Service Worker registration failed:', error)
                })
        }

        //
        // Load manifest.webmanifest
        fetch('/manifest.webmanifest')
            .then((response) => response.json())
            .then((manifest) => {
                const { icons } = manifest

                icons.forEach((icon) => {
                    if (icon.sizes === '48x48') {
                        const favicon = document.createElement('link')
                        favicon.rel = 'icon'
                        favicon.type = icon.type
                        favicon.href = icon.src
                        favicon.sizes = icon.sizes

                        document.head.appendChild(favicon)
                    }

                    const link = document.createElement('link')
                    link.rel = 'apple-touch-icon'
                    link.href = icon.src
                    link.sizes = icon.sizes

                    document.head.appendChild(link)
                })
            })
            .catch((error) => console.error('Error loading manifest:', error))
    })
}

//
// Add custom service worker
export const registerServiceWorker = () => true
