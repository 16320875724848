import React, { useEffect } from 'react'
import IconTimes from '@assets/svg/times.svg'

const styleSettings = {
    error: {
        titleColor: 'text-mandy',
        borderColor: 'border-l-mandy',
    },
    success: {
        titleColor: 'text-success',
        borderColor: 'border-l-success',
    },
}

export default function Notification({ notification, setNotification }) {
    const { mode, title, message } = notification
    const { borderColor, titleColor } = styleSettings[mode]

    useEffect(() => {
        if (notification !== null) {
            const id = setTimeout(() => setNotification(null), 7000)
            return () => clearTimeout(id)
        }
    }, [notification, setNotification])

    return (
        <div
            className={`fixed right-2.5 top-2.5 z-[10000] ml-2.5 max-w-md rounded border border-l-4 border-solid bg-white text-sm ${borderColor} px-5 py-4 shadow`}
        >
            <div className={`inline-block font-lmp-regular ${titleColor}`}>{title}</div>
            <div className='mt-1 font-lmp-ultra-light'>{message}</div>
            <div
                className='absolute right-2 top-2.5 h-7 w-7 cursor-pointer'
                onClick={() => setNotification(null)}
                role='button'
                aria-hidden
            >
                <IconTimes className='ml-auto h-5 w-5' />
            </div>
        </div>
    )
}
