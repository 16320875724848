import React, { createContext, useContext } from 'react'

// queries
import { useWebsiteData } from '@layout/queries'

// components
import Spinner from '@components/Spinner'
import FetchError from '@components/FetchError'

// Create the context with a default value of null
const SiteContext = createContext(null)

// Hook to use it all over the app
export const useSiteCtx = () => {
    return useContext(SiteContext)
}

export const SiteContextProvider = ({ children }) => {
    const { data, isLoading, isError } = useWebsiteData()

    if (isLoading) {
        return <Spinner mode='FULLSCREEN' />
    }

    if (isError) {
        return <FetchError />
    }

    return <SiteContext.Provider value={data}>{children}</SiteContext.Provider>
}
