import React, { useState } from 'react'
import { QueryClientProvider } from '@tanstack/react-query'

// context
import { SiteContextProvider } from '@layout/context/siteContext'
import { UserContextProvider } from '@layout/context/userContext'

// hooks
import useReactQueryClient from '@hooks/useReactQueryClient'

// components
import Notification from '@layout/components/notification'
import WebsiteValidation from '@layout/components/websiteValidation'

export default function Layout({ children }) {
    const [notification, setNotification] = useState(null)
    const queryClient = useReactQueryClient(setNotification)

    return (
        <QueryClientProvider client={queryClient}>
            <WebsiteValidation>
                <SiteContextProvider>
                    <UserContextProvider>{children}</UserContextProvider>
                </SiteContextProvider>
                {notification && <Notification notification={notification} setNotification={setNotification} />}
            </WebsiteValidation>
        </QueryClientProvider>
    )
}
