import React from 'react'

const colorSettings = {
    neutral: {
        bgColor: 'bg-haze',
        ringColor: 'ring-haze',
        iconColor: 'fill-white stroke-white',
        borderColor: 'border-transparent',
        textColor: '!text-white',
    },
    secondary: {
        bgColor: 'bg-emperor',
        ringColor: 'ring-emperor',
        iconColor: 'fill-white stroke-white',
        borderColor: 'border-transparent',
        textColor: '!text-white',
    },
    tertiary: {
        bgColor: 'bg-white',
        ringColor: 'ring-emperor',
        iconColor: 'fill-tundora stroke-emperor',
        borderColor: 'border-emperor',
        textColor: '!text-tundora',
    },
    error: {
        bgColor: 'bg-mandy',
        ringColor: 'ring-mandy',
        iconColor: 'fill-white stroke-white',
        borderColor: 'border-transparent',
        textColor: '!text-white',
    },
    success: {
        bgColor: 'bg-success',
        ringColor: 'ring-success',
        iconColor: 'fill-white stroke-white',
        borderColor: 'border-transparent',
        textColor: '!text-white',
    },
    studio: {
        bgColor: 'bg-studio',
        ringColor: 'ring-studio',
        iconColor: 'fill-white stroke-white',
        borderColor: 'border-transparent',
        textColor: '!text-white',
    },
}

export default function Action({ text, icon: Icon, as: DOMElement = 'button', type = 'button', mode, ...props }) {
    const { bgColor, ringColor, iconColor, borderColor, textColor } = colorSettings[mode ?? 'neutral']

    return (
        <DOMElement
            {...props}
            type={type}
            className={`inline-flex cursor-pointer appearance-none items-center justify-center rounded border ${borderColor} ${bgColor} px-3.5 py-2.5 shadow ${ringColor} h-8 hover:ring-1 hover:ring-offset-2 focus:outline-none disabled:cursor-not-allowed disabled:bg-plata disabled:ring-plata`}
        >
            {Icon && <Icon className={`-ml-1 mr-1.5 h-4 w-4 ${iconColor}`} aria-hidden='true' />}
            <span className={`font-lmp-regular text-sm not-italic ${textColor}`}>{text}</span>
        </DOMElement>
    )
}
