import React from 'react'
import Action from '@components/Action'
import IconYogaMudraAnjali from '@assets/svg/mudra_anjali.svg'

export default function FetchError({ mode = '', showReload = true }) {
    function refreshPage() {
        window.location.reload()
    }

    const component = (
        <div className='grid rounded border border-solid border-mandy bg-mandy/5 p-8 pt-12 text-center text-sm text-mandy shadow-sm'>
            <div className='mx-auto max-w-md'>
                <IconYogaMudraAnjali className='mx-auto h-7 w-7 stroke-current' />
                <p className='mb-4 mt-1.5 italic text-mandy'>
                    Ocurrió un error al cargar los datos. Refresca tu navegador, si el problema persiste reintenta en
                    unos minutos.
                </p>
                {showReload && <Action text='Reintentar' onClick={refreshPage} mode='error' />}
            </div>
        </div>
    )

    if (mode === 'SECTION') {
        return (
            <section className='w-full px-12 md:px-20'>
                <div className='mx-auto max-w-screen-xl'>{component}</div>
            </section>
        )
    }

    return component
}
